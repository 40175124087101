import styled from 'styled-components';

const CustomerActionsStyle = styled.div`
	&.customerActions {
		position: relative;
		display: flex;
		flex: 1 1 auto;
		justify-content: center;
		padding-right: var(--sp3x);
		padding-left: var(--sp3x);
		padding-top: calc(var(--sectionToSection) + 4%);
		padding-bottom: var(--sectionToSection);
		transition: all var(--trTime) ease-out;
		overflow: hidden;
		z-index: 1;

		.elemFlexWrap {
			display: inline-flex;
			flex-flow: column;
			justify-content: center;

			&:nth-child(2) {
				justify-content: flex-end;
			}
		}

		svg {
			fill: var(--color2);

			text {
				tspan {
					fill: var(--color1);
				}
			}
		}

		.firstElem,
		.midBackImg,
		.lastElemCont {
			position: relative;

			.lastBackImg,
			.arrow1,
			.arrow2 {
				position: absolute;
			}
		}

		.firstElem {
			.firstElemItem {
				&:last-child {
					.topTitle {
						padding-right: 0;
					}
				}

				.arrow1 {
					pointer-events: none;
					bottom: -10px;
					right: 20px;
					transform: translateX(120%);
					width: 142px;
					height: 85px;
				}

				.topTitle {
					padding-right: var(--sp4x);
					display: inline-block;
					position: relative;

					.bottomLine {
						position: absolute;
						bottom: 5%;
						right: 0;
						width: 100%;
						height: 4px;
						background-color: var(--color2);
						transition: all var(--trTime) linear;
					}
				}
			}
		}

		.midElem {
			pointer-events: none;
			margin-top: -15px;
			margin-bottom: -30px;
			display: flex;
			justify-content: flex-end;
			position: static;
			z-index: 2;

			.midBackImg {
				width: 160px;
				height: 140px;
				margin-right: 15px;

				text {
					font-size: calc(var(--h5) + 10px);
					font-family: var(--fontFamily2) !important;
					font-weight: 300 !important;
					font-style: var(--ffItalic) !important;
				}

				tspan {
					text-anchor: middle;
				}
			}

			.arrow2 {
				top: 21%;
				right: -20px;
				width: 73px;
				height: 90px;
			}
		}

		.lastElem {
			.lastElemCont {
				height: 95px;
				padding: 0 25px;
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;

				.lastBackImg {
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					z-index: 1;
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
			padding-right: 45px;
			padding-left: 45px;

			.elemFlexWrap {
				padding-right: 145px;
			}

			.firstElem {
				.firstElemItem {
					.arrow1 {
						bottom: 0;
						width: 236px;
						height: 137px;
					}

					.topTitle {
						.bottomLine {
							bottom: 8%;
							height: 6px;
						}
					}
				}
			}

			.midElem {
				margin-top: -75px;
				width: calc(100% + 155px);

				.midBackImg {
					width: 210px;
					height: 180px;
				}

				.arrow2 {
					top: 70%;
					right: 5%;
					width: 115px;
					height: 150px;
					transform: rotate(70deg);
				}
			}

			.lastElem {
				display: flex;
				justify-content: flex-end;

				.lastElemCont {
					display: inline-flex;
					height: 150px;
					padding: 0 67px;
				}
			}
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
			.firstElem {
				.firstElemItem {
					display: inline-block;
				}
			}

			.lastElem {
				display: flex;
				justify-content: flex-end;

				.lastElemCont {
					display: inline-flex;
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
			padding-right: 60px;
			padding-left: 60px;

			.elemFlexWrap {
				padding-right: 130px;
			}

			.firstElem {
				.firstElemItem {
					.topTitle {
						.bottomLine {
							bottom: 10%;
							height: 6px;
						}
					}

					.arrow1 {
						bottom: 50%;
						transform: translateX(100%);
						width: 247px;
						height: 150px;
					}
				}
			}

			.midElem {
				width: calc(100% + 135px);
				margin-top: -77px;
				margin-bottom: -93px;

				.arrow2 {
					top: 15%;
					right: -25%;
					width: 105px;
					height: 140px;
				}

				.midBackImg {
					width: 210px;
					height: 180px;
				}
			}

			.lastElem {
				.lastElemCont {
					height: 150px;
					margin-right: -25px;
					padding: 0 67px;
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
			.elemFlexWrap {
				padding-right: 160px;
			}

			.firstElem {
				.firstElemItem {
					.arrow1 {
						width: 267px;
						height: 152px;
						bottom: 68%;
						transform: translateX(130%);
					}

					.topTitle {
						.bottomLine {
							bottom: 9%;
							height: 8px;
						}
					}
				}
			}

			.midElem {
				margin-top: -82px;
				margin-bottom: -95px;
				width: calc(100% + 160px);

				.arrow2 {
					top: -15%;
					right: -30%;
					width: 135px;
					height: 170px;
					transform: rotate(5deg);
				}

				.midBackImg {
					width: 210px;
					height: 180px;
				}
			}

			.lastElem {
				.lastElemCont {
					margin-right: -145px;
					height: 240px;
					padding: 0 80px;
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
			.elemFlexWrap {
				padding-right: 160px;
			}

			.firstElem {
				.firstElemItem {
					.arrow1 {
						width: 267px;
						height: 152px;
						bottom: 68%;
						transform: translateX(130%);
					}

					.topTitle {
						.bottomLine {
							bottom: 9%;
							height: 8px;
						}
					}
				}
			}

			.midElem {
				margin-top: -80px;
				margin-bottom: -95px;
				width: calc(100% + 160px);

				.arrow2 {
					top: -0%;
					right: -40%;
					width: 140px;
					height: 170px;
				}

				.midBackImg {
					width: 210px;
					height: 180px;
				}
			}

			.lastElem {
				.lastElemCont {
					margin-right: -145px;
					height: 240px;
					padding: 0 80px;
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
			.elemFlexWrap {
				padding-right: 160px;
			}

			.firstElem {
				.firstElemItem {
					.arrow1 {
						width: 250px;
						height: 145px;
						bottom: 70%;
						transform: translateX(140%);
					}

					.topTitle {
						.bottomLine {
							bottom: 9%;
							height: 8px;
						}
					}
				}
			}

			.midElem {
				margin-top: -80px;
				margin-bottom: -100px;
				width: calc(100% + 160px);

				.arrow2 {
					top: -20%;
					right: -40%;
					width: 140px;
					height: 172px;
				}

				.midBackImg {
					width: 210px;
					height: 180px;
				}
			}

			.lastElem {
				.lastElemCont {
					margin-right: -185px;
					height: 260px;
					padding: 0 75px 0 100px;

					.lastBackImg {
						right: 0;
					}
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
			.elemFlexWrap {
				padding-right: 160px;
			}

			.firstElem {
				.firstElemItem {
					.topTitle {
						.bottomLine {
							bottom: 9%;
							height: 10px;
						}
					}

					.arrow1 {
						bottom: 70%;
						width: 330px;
						height: 186px;
						transform: translateX(135%);
					}
				}
			}

			.midElem {
				margin-top: -105px;
				margin-bottom: -125px;
				width: calc(100% + 175px);

				.midBackImg {
					width: 270px;
					height: 235px;
				}

				.arrow2 {
					top: -15%;
					width: 180px;
					height: 226px;
					right: -45%;
				}
			}

			.lastElem {
				.lastElemCont {
					margin-right: -185px;
					height: 325px;
					padding: 0 95px 0 110px;
				}
			}
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXL}) {
			.elemFlexWrap {
				padding-right: 190px;
			}

			.firstElem {
				.firstElemItem {
					.topTitle {
						.bottomLine {
							bottom: 9%;
							height: 10px;
						}
					}

					.arrow1 {
						bottom: 70%;
						width: 410px;
						height: 235px;
						transform: translateX(135%);
					}
				}
			}

			.midElem {
				margin-top: -130px;
				margin-bottom: -150px;
				width: calc(100% + 210px);

				.midBackImg {
					width: 335px;
					height: 290px;
				}

				.arrow2 {
					top: -15%;
					width: 220px;
					height: 282px;
					right: -45%;
				}
			}

			.lastElem {
				.lastElemCont {
					margin-right: -200px;
					height: 390px;
					padding: 0 140px;
				}
			}
		}
	}
`;

export default CustomerActionsStyle;
