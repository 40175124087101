import styled from 'styled-components';

const FooterStyle = styled.footer`
	--fmPadding: var(--sp7x) var(--sp4x) var(--sp4x);
	--fmTopContMarginBottom: var(--sp10x);
	--fmAddressContPaddingRight: 0;
	--fmAddressWidth: 50%;
	--fmAddressColPadding: var(--sp2x);
	--fmAddressColPadding: var(--sp2x);
	--fmCityMarginBottom: var(--sp1x);
	--fmAddressMarginBottom: var(--sp4x);
	--fmMenuColPadding: 0;
	--fmMenuItemMarginBottom: var(--sp2x);

	position: relative;

	> * {
		position: relative;
		z-index: 1;
	}

	.InterestedQuestionWrapper {
		~ div {
			position: sticky;
			top: 0;
		}
	}

	.TickerAndCustomerActionsCont {
		height: 100vh;
		color: var(--color2);
		background-color: var(--color1);

		.TickerAndCustomerActionsWrap {
			height: 100vh;
			display: flex;
			flex-direction: column;
		}
	}

	.footer-main {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		padding: var(--fmPadding);
		z-index: 0;
		color: var(--color2);
		background-color: var(--color1);

		.footer-top-cont {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			margin-bottom: var(--fmTopContMarginBottom);

			.addresses-cont {
				flex: 1;
				padding-right: var(--fmAddressContPaddingRight);

				.addresses-row {
					display: flex;
					margin-left: calc(var(--fmAddressColPadding) / -2);
					margin-right: calc(var(--fmAddressColPadding) / -2);

					.address-item {
						width: var(--fmAddressWidth);
						display: flex;
						flex-direction: column;
						padding-left: calc(var(--fmAddressColPadding) / 2);
						padding-right: calc(var(--fmAddressColPadding) / 2);

						.fm-city {
							margin-bottom: var(--fmCityMarginBottom);
						}

						.top-block {
							margin-bottom: var(--fmAddressMarginBottom);
						}

						a {
							width: fit-content;
							display: inline-flex;
							flex-direction: column;

							p {
								width: fit-content;
							}

							@media (hover: hover) {
								&:hover {
									text-decoration: underline;
								}
							}
						}
					}
				}
			}

			.menu-items-cont {
				.menu-items-row {
					display: flex;
					margin: 0 calc(var(--fmMenuColPadding) / -2);
					text-align: right;

					.menu-col {
						display: flex;
						flex-direction: column;
						margin-bottom: calc(-1 * var(--fmMenuItemMarginBottom));
						padding: 0 calc(var(--fmMenuColPadding) / 2);

						.menu-el {
							margin-bottom: var(--fmMenuItemMarginBottom);
						}
					}
				}
			}
		}

		.footer-bottom-cont {
			display: flex;
			justify-content: space-between;
		}
	}

	&.dark-container {
		.footer-main {
			color: var(--color1);
			background-color: var(--color2);

			.soc-icon-item {
				.social-icon-border {
					border-color: var(--color1);
				}
			}
		}
	}

	&:not(.dark-container) {
		.TickerAndCustomerActionsCont {
			color: var(--color1);
			background-color: var(--color2);

			.TickerAndCustomerActionsWrap {
				svg {
					fill: var(--color1);

					text {
						tspan {
							fill: var(--color2);
						}
					}

					&.strike-cont {
						path {
							stroke: var(--color1);
						}
					}
				}

				.bottomLine {
					background-color: var(--color1) !important;
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		.footer-main {
			.footer-top-cont {
				.addresses-cont {
					width: 100%;
					flex: auto;

					.addresses-row {
						.address-item {
							margin-bottom: var(--sp7x);
						}
					}
				}

				.menu-items-cont {
					width: 100%;

					.menu-items-row {
						text-align: left;
						margin-left: calc(var(--fmAddressColPadding) / -2);
						margin-right: calc(var(--fmAddressColPadding) / -2);

						.menu-col {
							width: 50%;
							padding-left: calc(var(--fmAddressColPadding) / 2);
							padding-right: calc(var(--fmAddressColPadding) / 2);
						}
					}
				}
			}

			.footer-bottom-cont {
				flex-direction: column;

				.footer-social-icons {
					display: flex;
					flex-wrap: wrap;
					margin-right: calc(-1 * var(--sp3x));

					.soc-icon-item {
						margin-bottom: var(--sp4x);
					}
				}
			}
		}
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--fmPadding: var(--sp7x) var(--sp6x) var(--sp4x);
		--fmTopContMarginBottom: var(--sp7x);
		--fmAddressContPaddingRight: 0;
		--fmAddressWidth: 33.3333%;
		--fmAddressColPadding: var(--sp4x);
		--fmCityMarginBottom: var(--sp1x);
		--fmAddressMarginBottom: var(--sp4x);
		--fmMenuColPadding: 0;
		--fmMenuItemMarginBottom: var(--sp2x);

		.footer-main {
			.footer-top-cont {
				.addresses-cont {
					width: 100%;
					flex: auto;

					.addresses-row {
						.address-item {
							margin-bottom: var(--sp7x);
						}
					}
				}

				.menu-items-cont {
					width: 100%;

					.menu-items-row {
						text-align: left;
						margin-left: calc(var(--fmAddressColPadding) / -2);
						margin-right: calc(var(--fmAddressColPadding) / -2);

						.menu-col {
							width: 33.33333%;
							padding-left: calc(var(--fmAddressColPadding) / 2);
							padding-right: calc(var(--fmAddressColPadding) / 2);
						}
					}
				}
			}

			.footer-bottom-cont {
				flex-direction: column;

				.footer-social-icons {
					display: flex;
					flex-wrap: wrap;
					margin-right: calc(-1 * var(--sp3x));

					.soc-icon-item {
						margin-bottom: var(--sp4x);
					}
				}
			}
		}
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) {
		--fmPadding: var(--sp8x) var(--sp8x) var(--sp4x);
		--fmTopContMarginBottom: var(--sp11x);
		--fmAddressContPaddingRight: 0;
		--fmAddressWidth: 33.3333%;
		--fmAddressColPadding: var(--sp8x);
		--fmCityMarginBottom: var(--sp1x);
		--fmAddressMarginBottom: var(--sp4x);
		--fmMenuColPadding: 0;
		--fmMenuItemMarginBottom: var(--sp2x);

		.footer-main {
			.footer-top-cont {
				.addresses-cont {
					width: 100%;
					flex: auto;

					.addresses-row {
						.address-item {
							margin-bottom: var(--sp7x);
						}
					}
				}

				.menu-items-cont {
					width: 100%;

					.menu-items-row {
						text-align: left;
						margin-left: calc(var(--fmAddressColPadding) / -2);
						margin-right: calc(var(--fmAddressColPadding) / -2);

						.menu-col {
							width: 33.33333%;
							padding-left: calc(var(--fmAddressColPadding) / 2);
							padding-right: calc(var(--fmAddressColPadding) / 2);
						}
					}
				}
			}

			.footer-bottom-cont {
				flex-direction: column;

				.footer-social-icons {
					display: flex;
					flex-wrap: wrap;
					margin-right: calc(-1 * var(--sp3x));

					.soc-icon-item {
						margin-bottom: var(--sp4x);
					}
				}
			}
		}
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) {
		--fmPadding: var(--sp8x) var(--sp10x) var(--sp4x);
		--fmTopContMarginBottom: var(--sp15x);
		--fmAddressContPaddingRight: var(--sp15x);
		--fmAddressWidth: 33.3333%;
		--fmAddressColPadding: var(--sp3x);
		--fmCityMarginBottom: var(--sp1x);
		--fmAddressMarginBottom: var(--sp4x);
		--fmMenuColPadding: var(--sp7x);
		--fmMenuItemMarginBottom: var(--sp2x);
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) {
		--fmPadding: var(--sp8x) var(--sp10x) var(--sp4x);
		--fmTopContMarginBottom: var(--sp15x);
		--fmAddressContPaddingRight: var(--sp25x);
		--fmAddressWidth: 33.3333%;
		--fmAddressColPadding: var(--sp3x);
		--fmCityMarginBottom: var(--sp1x);
		--fmAddressMarginBottom: var(--sp4x);
		--fmMenuColPadding: var(--sp8x);
		--fmMenuItemMarginBottom: var(--sp2-5x);
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) {
		--fmPadding: var(--sp8x) var(--sp10x) var(--sp5x);
		--fmTopContMarginBottom: var(--sp14x);
		--fmAddressContPaddingRight: var(--sp30x);
		--fmAddressWidth: 33.3333%;
		--fmAddressColPadding: var(--sp4x);
		--fmCityMarginBottom: var(--sp1x);
		--fmAddressMarginBottom: var(--sp4x);
		--fmMenuColPadding: var(--sp9x);
		--fmMenuItemMarginBottom: var(--sp2-5x);
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) {
		--fmPadding: var(--sp10x) var(--sp18x) var(--sp6x);
		--fmTopContMarginBottom: var(--sp15x);
		--fmAddressContPaddingRight: var(--sp54x);
		--fmAddressWidth: 33.3333%;
		--fmAddressColPadding: var(--sp8x);
		--fmCityMarginBottom: var(--sp2x);
		--fmAddressMarginBottom: var(--sp5x);
		--fmMenuColPadding: var(--sp7x);
		--fmMenuItemMarginBottom: var(--sp2-5x);
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXL}) {
		--fmPadding: var(--sp14x) var(--sp37x) var(--sp7x);
		--fmTopContMarginBottom: var(--sp20x);
		--fmAddressContPaddingRight: var(--sp56x);
		--fmAddressWidth: 25%;
		--fmAddressColPadding: var(--sp11x);
		--fmCityMarginBottom: var(--sp2x);
		--fmAddressMarginBottom: var(--sp6x);
		--fmMenuColPadding: var(--sp16x);
		--fmMenuItemMarginBottom: var(--sp4x);
	}
`;

export default FooterStyle;
