import { memo as Memo, useMemo, useState, useEffect, useRef } from 'react';
import { usePathname, useParams } from 'next/navigation';

//* HOC's
import withUIContext from '@context/consumerHOC/UIConsumer';
import withLanguageContext from '@/context/consumerHOC/LanguageConsumer';

//* JSONs
import config from '@lib/config.json';

//* Components
import SocIcon from '@components/global/SocIcon';
import CustomLink from '@components/global/CustomLink';
import CustomerActions from '@components/global/Footer/CustomerActions';

//* Styles
import FooterStyle from './style';

const Footer = Memo(({ winWidth, winHeight, translate, globalData }) => {
    //! Pathname, params
    const pathname = usePathname();
    const params = useParams();

    //! States
    const [isLight, setIsLight] = useState(true);
    const [footerPadding, setFooterPadding] = useState(0);

    //! Routes
    const routes = useMemo(() => config.routes.default, [config]);

    //! Refs
    const contRef = useRef();
    const footerMainRef = useRef();

    //! Detecting colors
    useEffect(() => {
        setIsLight(
            // pathname !== routes.home.path &&
            pathname !== routes.blog.path && pathname !== routes.team.path && !(pathname.split('/')[1] === routes.blog.name && pathname.includes(params.slug))
        );
    }, [pathname, params]);

    //! Getting year
    const year = useMemo(() => {
        const date = new Date();
        return date.getFullYear();
    }, []);

    //! Updating footer padding
    useEffect(() => {
        footerMainRef?.current && setFooterPadding(footerMainRef?.current?.getBoundingClientRect().height);
    }, [winWidth, winHeight, pathname, globalData, footerMainRef.current]);

    return (
        <FooterStyle
            ref={contRef}
            style={{ paddingBottom: `${footerPadding}px` }}
            className={`${isLight ? '' : 'dark-container'}`}>
            <div className='TickerAndCustomerActionsCont'>
                <div className='TickerAndCustomerActionsWrap'>
                    <CustomerActions resp={true} />
                </div>
            </div>

            <div
                ref={footerMainRef}
                className='footer-main'>
                <div className='footer-top-cont'>
                    <div className='addresses-cont'>
                        <div className='addresses-row'>
                            {globalData.contact_information.addresses.map((i, k) => (
                                <div
                                    key={k}
                                    className='address-item'>
                                    <a
                                        href={i.link}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        className='crCircle top-block'
                                        aria-label={`${i.city} ${i.address} ${i.location}`}>
                                        <p className={`fm-city p-m font-poppins font-semi-bold`}>{i.city}</p>

                                        <p className='fm-address p font-poppins font-regular'>{i.address}</p>

                                        <p className='fm-location p font-poppins font-regular'>{i.location}</p>
                                    </a>

                                    <a
                                        target='_blank'
                                        aria-label={i.phone}
                                        rel='noopener noreferrer'
                                        href={`tel:${i.phone.replace(/\s+/g, '')}`}
                                        className='fm-phone p-m font-anonymous font-bold crCircle'>
                                        {i.phone}
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className='menu-items-cont'>
                        <div className='menu-items-row'>
                            <div className='menu-col'>
                                <CustomLink
                                    className='menu-el p-m font-poppins font-regular'
                                    content={translate(routes['about-us'].name)}
                                    ariaLabel={translate(routes['about-us'].name)}
                                    url={routes['about-us'].path}
                                />
                                <CustomLink
                                    className='menu-el p-m font-poppins font-regular'
                                    content={translate(routes.services.name)}
                                    ariaLabel={translate(routes.services.name)}
                                    url={routes.services.path}
                                />
                                <CustomLink
                                    className='menu-el p-m font-poppins font-regular'
                                    content={translate(routes.portfolio.name)}
                                    ariaLabel={translate(routes.portfolio.name)}
                                    url={routes.portfolio.path}
                                />
                                <CustomLink
                                    className='menu-el p-m font-poppins font-regular'
                                    content={translate(routes.contact.name)}
                                    ariaLabel={translate(routes.contact.name)}
                                    url={routes.contact.path}
                                />
                            </div>

                            <div className='menu-col'>
                                <CustomLink
                                    className='menu-el p-m font-poppins font-regular'
                                    content={translate(routes.team.name)}
                                    ariaLabel={translate(routes.team.name)}
                                    url={routes.team.path}
                                />
                                <CustomLink
                                    className='menu-el p-m font-poppins font-regular'
                                    content={translate(routes.awards.name)}
                                    ariaLabel={translate(routes.awards.name)}
                                    url={routes.awards.path}
                                />
                                <CustomLink
                                    className='menu-el p-m font-poppins font-regular'
                                    content={translate(routes.blog.name)}
                                    ariaLabel={translate(routes.blog.name)}
                                    url={routes.blog.path}
                                />
                                <CustomLink
                                    className='menu-el p-m font-poppins font-regular'
                                    content={translate(routes.legal.name)}
                                    ariaLabel={translate(routes.legal.name)}
                                    url={routes.legal.path}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='footer-bottom-cont'>
                    <div className='socials-cont'>
                        <SocIcon
                            className='footer-social-icons'
                            isFooter
                            type={2}
                            menuIcon={true}
                            isSocial={true}
                        />
                    </div>

                    <p className='p font-anonymous font-regular'>{`© ${year} Concept Studio.`}</p>
                </div>
            </div>
        </FooterStyle>
    );
});

export default withUIContext(withLanguageContext(Footer, ['translate']), ['winHeight', 'winWidth', 'globalData']);
