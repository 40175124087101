import { forwardRef } from 'react';

const HTMLContainer = forwardRef((props, ref) => {
    return (
        <section
            ref={ref}
            className={`${props.className || ''} container ${props.full ? ' full' : ''}`}>
            {props.children}
        </section>
    );
});

HTMLContainer.displayName = 'HTMLContainer'

export default HTMLContainer;
