import { memo as Memo, useMemo, useRef, useEffect, useState } from 'react';
import { gsap } from 'gsap';

//* HOC's
import withLanguageContext from '@/context/consumerHOC/LanguageConsumer';

//* Components
import CustomLink from '@components/global/CustomLink';
import Stroke from '@components/global/SvgComponents/Stroke';
import StarSvg from '@components/global/SvgComponents/StarSvg';
import ArrowLeft from '@components/global/SvgComponents/ArrowLeft';
import ArrowRight from '@components/global/SvgComponents/ArrowRight';

//* Styles
import CustomerActionsStyle from './style';

const CustomerActions = Memo(({ resp, translate }) => {
    //! States
    const [act, setAct] = useState(false);

    //! Refs
    const ref = useRef();
    const addToRefs = useRef([]);

    const tl = useRef();

    //! Animation
    useEffect(() => {
        tl.current = gsap
            .timeline({
                paused: true,
            })
            .staggerTo(addToRefs.current, 0, { width: '0%' }, 0.3);
    }, []);

    useEffect(() => {
        act ? tl.current.play() : tl.current.reverse();
    }, [act, tl]);

    //! First Element
    const firstElement = useMemo(
        () => (
            <CustomLink
                url={'/contact-form/'}
                className={'firstElem'}
                ariaLabel={"let's collaborate"}
                content={
                    <div
                        ref={ref}
                        onMouseEnter={() => setAct(true)}
                        onMouseLeave={() => setAct(false)}>
                        {['customerActionsWord1', 'customerActionsWord2'].map((item, i) => {
                            return (
                                <div
                                    className={'firstElemItem'}
                                    key={i}>
                                    <p className={`topTitle h2 font-semi-bold font-poppins`}>
                                        {translate(item)}

                                        <span
                                            className={`bottomLine`}
                                            ref={(el) => (addToRefs.current[i] = el)}
                                        />

                                        {i === 0 && (
                                            <span className={'arrow1'}>
                                                <ArrowLeft animate={resp} />
                                            </span>
                                        )}
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                }
            />
        ),
        [resp]
    );

    //! Middle Element
    const middleElement = useMemo(
        () => (
            <div className={'midElem'}>
                <div className={'midBackImg'}>
                    <StarSvg
                        text={translate('or')}
                        animate={resp}
                    />
                    <div className={'arrow2'}>
                        <ArrowRight animate={resp} />
                    </div>
                </div>
            </div>
        ),
        [resp]
    );

    //! Last Element
    const lastElement = useMemo(
        () => (
            <div className={'lastElem'}>
                <div className={'lastElemCont'}>
                    <CustomLink url={'/team/'} ariaLabel={translate('joinOurTeam')}>
                        <p className={`h3 font-light font-italic font-poppins`}>{translate('joinOurTeam')}</p>

                        <div className={'lastBackImg'}>
                            <Stroke animate={resp} />
                        </div>
                    </CustomLink>
                </div>
            </div>
        ),
        [resp]
    );

    return (
        <CustomerActionsStyle className={`customerActions ${resp ? 'active' : ''}`}>
            <div className={'elemFlexWrap'}>
                {firstElement}
                {middleElement}
                {lastElement}
            </div>
        </CustomerActionsStyle>
    );
});

export default withLanguageContext(CustomerActions, ['translate']);
