import { memo as Memo, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { MotionPathPlugin } from 'gsap/dist/MotionPathPlugin';

gsap.registerPlugin(MotionPathPlugin);

const ArrowLeft = Memo(({ animate, text, ...props }) => {
	//! Refs
	const arrLeftRef = useRef();

	useEffect(() => {
		animate &&
			gsap.to(arrLeftRef.current, {
				duration: 0.6,
				repeat: -1,
				repeatDelay: 0,
				yoyo: true,
				ease: 'power1.inOut',
				motionPath: {
					path: '#path',
					align: '#path',
					autoRotate: 220,
					alignOrigin: [0.3, 0.3],
				},
			});
	}, [animate]);

	return (
		<svg
			className='svg-max'
			viewBox='0 0 337.21 194.22'
			preserveAspectRatio='none'>
			<path
				ref={arrLeftRef}
				d='M214.1,38.06c-36.79-5.14-69.86-2.83-98.28,6.86A147.59,147.59,0,0,0,56.67,82.21a153.93,153.93,0,0,0-33,51.8L10.47,107.45,3.31,111l16.11,32.34,3.26,7.22L61.55,133l-3.29-7.29L30.73,138.17a147,147,0,0,1,32-50.67,139.8,139.8,0,0,1,56-35.09C145.81,43.2,177.56,41,213,46l4,.55,1.1-7.92Z'
			/>
			<path
				id='path'
				strokeWidth='8px'
				fill='none'
				stroke='none'
				d='M148.42,36.08C127.27,39.66,104,47.64,82.5,64.3A149.62,149.62,0,0,0,63.28,82.18'
			/>
		</svg>
	);
});

export default ArrowLeft;
