import { memo as Memo, useEffect, useRef } from 'react';
import { TweenMax } from 'gsap';

const StarSvg = Memo(({ animate, text, ...props }) => {
	//! Refs
	const starRef = useRef();

	useEffect(() => {
		animate &&
			TweenMax.to(starRef.current, 5, {
				rotationY: 360,
				repeat: -1,
				ease: 'none',
			});
	}, [animate]);

	return (
		<svg
			ref={starRef}
			className='svg-max'
			preserveAspectRatio='none'
			viewBox='0 0 283.24 245.519'>
			<g transform='translate(44.706 -87.721) rotate(22)'>
				<path
					d='M83.967,68.869,104.294,0l41.119,59.935,60.654-13.522L189.8,94.466l78.21,30.694-82.03,29.563,21.74,76.462L134.686,179.6l-49.1,47.456-6.863-63.581L0,164.267l51.776-45.158L17.995,63.827l58.517,4.528L82,68.928l1.863.348.107-.408'
					transform='matrix(0.921, -0.391, 0.391, 0.921, 0, 104.719)'
					strokeWidth='6'
				/>
				<text
					transform='translate(162.5 180.5)'
					fontFamily='Poppins-semiBold, Poppins'
					fontWeight='600'>
					<tspan>{text}</tspan>
				</text>
			</g>
		</svg>
	);
});

export default StarSvg;
