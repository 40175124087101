import { memo as Memo } from 'react';

//* Components
import Footer from '@components/global/Footer';

const Page = Memo(({ mainProps, ...props }) => {
	return (
		<>
			<main
				{...mainProps}
				className={`page ${props.className || ''}`}>
				{props.children}
			</main>

			<Footer />
		</>
	);
});

export default Page;
